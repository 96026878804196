<template>
	<div>
		<!-- Full screen takeover -->
		<div v-if="active">
			<CloDialog id="fullscreenAd" @close="hide" class="Dialog--ad" v-if="!minimize">
				<template #body>
					<a v-if="campaignId === 'spanish'" href="/bow/content/language-en" @click="hide">
						<img :src="images.spanish[0]" alt="Now available in Spanish" />
					</a>
					<a v-else :id="promo.buttonId" :href="'/bow/subscribe' + (promo.couponCode ? `?couponCode=${promo.couponCode}` : '')">
						<img :src="randomImage" alt="Subscribe to Birds of the World" />
					</a>
				</template>
			</CloDialog>
			<div v-if="minimize && campaignId !== 'spanish'" class="FullscreenAd-minimized">
				<span class="FullscreenAd-minimized-text">
					<span>{{ promo.minimizedText }}</span>
					<a v-if="!accountPreview" href="?login">Sign in</a>
					<a href="/bow/subscribe">Learn more</a>
				</span>

				<button @click="minimize = false">
					<CloIcon size="sm" symbol="arrowUp" />
					<span class="is-visuallyHidden">Open</span>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import CloHeading from '@/base/CloHeading/CloHeading.vue';
import CloIcon from '@/icons/CloIcon/CloIcon.vue';
import CloDialog from '@/base/CloDialog/CloDialog.vue';
export default {
	components: {
		CloHeading,
		CloIcon,
		CloDialog,
	},
	data() {
		return {
			active: false,
			minimize: false,
			delay: 5, // 5 seconds
			images: {
				gbd: [],
				obd: [],
				gbbc: [],
				default: [
					'https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2024/02/BOW-Ad-White-Tern.png',
					'https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2024/02/BOW-Ad-Laughingthrush.png',
					'https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2024/02/BOW-Ad-Honey-Buzzard.png',
					'https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2024/02/BOW-Ad-Plover.png',
					'https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2024/02/BOW-Ad-Puffin.png',
					'https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2024/02/BOW-Ad-Western-Gull.png',
					'https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2024/02/BOW-Ad-Buffy-Fish-Owl.png',
				],
				spanish: ['https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2024/10/Spanish-Internal-Ad.png'],
			},
		};
	},
	props: {
		campaignId: {
			type: String,
			default: 'default', // warbler, lark, gbd
		},
		accountPreview: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		randomImage() {
			// TODO: change when GBBC 24 is over
			//const images = this.images[this.campaignId];
			const images = this.images.default;
			return images[Math.floor(Math.random() * images.length)];
		},
		promo() {
			let promo = {
				buttonId: 'bow_ad_account_default',
				couponCode: 'TRYMENOW',
				minimizedText: 'This content is available exclusively to Birds of the World Subscribers.',
			};
			if (this.campaignId === 'gbbc') {
				promo.couponCode = 'GBBC24';
				promo.buttonId = 'bow_ad_account_special';
			}
			if (this.campaignId === 'gbd') {
				/*
				promo.couponCode = 'GLOBALBIGDAY2023'
				promo.buttonId = 'bow_ad_account_special'
				*/
				promo.couponCode = 'OCTOBERBIGDAY';
				promo.buttonId = 'bow_ad_account_special';
			}
			if (this.accountPreview) {
				promo.minimizedText = 'Enjoy complimentary access to this Birds of the World species account.';
			}
			if (this.campaignId === 'novemberGift') {
				promo.couponCode = 'GIFTOFBIRDS15';
				promo.buttonId = 'bow_ad_account_special';
			}
			if (this.campaignId === 'decemberGift') {
				promo.couponCode = 'GIFTOFBIRDS15';
				promo.buttonId = 'bow_ad_account_special';
			}
			return promo;
		},
	},
	mounted() {
		// show minimized if cookie is valid
		if (document.cookie.split('; ').find((row) => row.startsWith('bowSubscribeAd'))) {
			this.minimize = true;
		}
		// animate in after delay
		setTimeout(() => {
			this.active = true;
		}, this.delay * 1000);
	},
	methods: {
		hide() {
			this.minimize = true;
			let date = new Date();
			let expiration = 1000 * 60 * 30; // 30 minutes
			if (this.campaignId === 'spanish') {
				expiration = 1000 * 60 * 60 * 24 * 7; // 7 days
			}
			date.setTime(date.getTime() + expiration);
			date = date.toUTCString();
			document.cookie = `bowSubscribeAd=; expires=${date}; path=/; SameSite=None; Secure`;
		},
	},
};
</script>

<style lang="scss">
.Dialog--ad {
	.Dialog-container {
		outline: 0;
		padding: 0;
		background-color: transparent;
		box-shadow: none;
		position: relative;
		max-width: calc(100vh - 0.5rem);
		max-height: calc(100vw - 0.5rem);
		@include breakpoint(xlarge) {
			max-width: calc(100vh - 3rem);
			max-height: calc(100vw - 3rem);
		}
	}
	.Dialog-container-close {
		top: -$space-md;
		@include breakpoint(medium) {
			right: $space-sm;
		}
		.Icon {
			fill: $color-white;
		}
	}
	.Dialog-container-body {
		padding: 2rem 1rem;
		@include breakpoint(medium) {
			padding: 2rem;
		}
		overflow-y: visible;
		display: grid;
		align-items: center;
		justify-content: center;
		text-align: center;
		img {
			max-width: 100%;
			max-height: 100%;
			width: auto;
			height: auto;
			box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
		}
	}
}
.FullscreenAd-minimized {
	z-index: 1001;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	@include inset-md;
	@include text(3);
	@include colorReverse;
	color: $color-white;
	background-color: $color-primary-5;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: $space-md;
}
.FullscreenAd-minimized-text {
	display: flex;
	gap: $space-sm $space-md;
	flex-wrap: wrap;
}
</style>
